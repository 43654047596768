import styled from 'styled-components'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import Image from 'components/Image'
import device from 'utils/device'
import Tags from 'components/blog/Tags'
import Minutes from 'components/blog/Minutes'

const excerpt = text => {
    if (text.length > 150) {
        return text.slice(0, 150) + '...'
    }

    return text
}

export default function ArticleCard({ data, as = 'h2' }) {
    const { t } = useTranslation('common')

    return (
        <Container key={data.slug}>
            <Content>
                <ImageContainer>
                    <Link href={`/articles/${data.slug}`} aria-label={data.name}>
                        <a />
                    </Link>
                    <Minutes>{t('min_read', { minutes: data.minutes })}</Minutes>
                    <Image src={data.image} alt={data.name} layout="fill" />
                </ImageContainer>
                <Details>
                    <Tags data={data} small />
                    <Name as={as}>
                        <Link href={`/articles/${data.slug}`} passHref>
                            <a>{data.name}</a>
                        </Link>
                    </Name>
                    <Excerpt>{excerpt(data.excerpt)}</Excerpt>
                    <Link href={`/articles/${data.slug}`} passHref>
                        <More>{t('read_more')}</More>
                    </Link>
                </Details>
            </Content>
        </Container>
    )
}

// width: 384px
const Container = styled.div`
    width: 33.33%;
    padding: 0 0.12rem;
    margin: 0 0 0.36rem 0;
    display: flex;
    ${device.mobile} {
        margin: 0 0 0.16rem 0;
        padding: 0;
        width: 100%;
    }
`

const Content = styled.div`
    background: #fff;
    width: 100%;
    border-radius: 0.24rem;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    
`

// height: 2.4rem;
// height: 1.9393rem;
const ImageContainer = styled.div`
    height: 2.2rem;
    position: relative;
    ${device.mobile} {
        height: 2.2rem;
    }
    > a {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
    }
`

const Details = styled.div`
    padding: 0.36rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    ${device.mobile} {
        padding: 0.24rem;
    }
`

const Name = styled.h2`
    font-size: 0.22rem;
    line-height: 1.27;
    font-weight: 600;
    margin: 0 0 0.16rem 0;
    a {
        display: inline-block;
    }
    a:hover {
        text-decoration: underline;
    }
`

const Excerpt = styled.p`
    margin: 0 0 0.24rem 0;
`

const More = styled.a`
    color: var(--color-blue);
    margin: auto 0 0 0;
`
