export function parse(string) {
    var obj = {}
    if (string) string.replace(/\+/g, ' ').split(/[&;]/).forEach(parseIt)
    return obj

    function parseIt(pair) {
        var len = pair.length
        if (!len) return
        var pos = pair.indexOf('=')
        if (pos < 0) pos = len
        var key = decodeURIComponent(pair.substr(0, pos))
        var val = decodeURIComponent(pair.substr(pos + 1))
        obj[key] = val
    }
}

export function stringify(obj) {
    var list = []
    if ('object' === typeof obj && obj !== null)
        Object.keys(obj).map(stringifyIt)
    return list.join('&')

    function stringifyIt(key) {
        var val = obj[key]
        if (val == null) return
        // if (val === "") return;
        if (val instanceof Function) return
        var pair = encodeURIComponent(key) + '=' + encodeURIComponent(val)
        list.push(pair)
    }
}
