import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react'
// import { useRouter } from 'next/router'

import device from 'utils/device'
import Logo from 'components/Logo'
import Download from './Download'

export default function Header() {
    const [isVisible, setIsVisible] = useState(false);
    // const [isIOS, setIos] = useState(false);
    const windowHeight = useRef(0);
    // const router = useRouter()

    // useEffect(() => {
    //     const iOS = () => {
    //         return [
    //           'iPad Simulator',
    //           'iPhone Simulator',
    //           'iPod Simulator',
    //           'iPad',
    //           'iPhone',
    //           'iPod'
    //         ].includes(navigator.platform)
    //         // iPad on iOS 13 detection
    //         || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    //       }
    //     var val = iOS()

    //     // navigator.platform.toLowerCase().includes('iphone') || navigator.platform.toLowerCase().includes('ipad') || navigator.platform.toLowerCase().includes('ipod');

    //     setIos(val)
    // },[router])

    // let isIOS = false
    // if (typeof window !== 'undefined' && typeof window?.navigator !== 'undefined') {
    //     isIOS = !(window.navigator.userAgent.toLowerCase().indexOf("android") > -1)
    //     // isIOS = navigator.platform.toLowerCase().includes('iphone') || navigator.platform.toLowerCase().includes('ipad') || navigator.platform.toLowerCase().includes('ipod') || navigator.platform.toLowerCase().includes('mac');
    //     // isIOS = [
    //     //     'iPad Simulator',
    //     //     'iPhone Simulator',
    //     //     'iPod Simulator',
    //     //     'iPad',
    //     //     'iPhone',
    //     //     'iPod'
    //     //   ].includes(navigator.platform)
    //     //   // iPad on iOS 13 detection
    //     //   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    // }

    useEffect(() => {

        const handleScroll = () => {
            if (!windowHeight.current) {
                windowHeight.current = window.innerHeight;
            }

            if (window.scrollY > windowHeight.current && !isVisible) {
                setIsVisible(true);
            } else if (window.scrollY < 50 && isVisible) {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isVisible]);

    // isios={isIOS}
    return (
        <Container fixed={isVisible}>
            <Wrapper>
                <Logo />
                <Download />
            </Wrapper>
        </Container>
    )
}

const Container = styled.div`
    background: var(--color-light);
    width: 100%;
    z-index: 5;
    position: fixed;
    border-bottom: 1px solid var(--color-border);
    transition: transform 300ms ease-in-out;
    transform: translateY(${props => !props.fixed ? '-100%': '0'});
`

const Wrapper = styled.div`
    width: var(--wrap);
    margin: 0 auto;
    padding-top: 0.16rem;
    padding-bottom: 0.16rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${device.mobile} {
        width: 100%;
        padding: 0.12rem 0.16rem;
        > div > a > svg {
            height: 0.33rem;
            width: auto;
        }
        > div > a > svg {
            height: 0.33rem;
            width: auto;
        }
    }
`


// > div > a:first-child {
//     display: ${props => props.isios ? 'none' : 'block'};
// }
// > div > a:last-child {
//     display: ${props => props.isios ? 'block' : 'none'};
// }