import styled from 'styled-components'

import Svg from 'components/Svg'

export default function Minutes({ children }) {
    return (
        <Container>
            <Svg name="clock" />
            {children}
        </Container>
    )
}

const Container = styled.div`
    position: absolute;
    height: 0.32rem;
    line-height: 0.32rem;
    border-radius: 0.16rem;
    background: #fff;
    padding: 0 0.12rem 0 0.08rem;
    top: 0.16rem;
    left: 0.18rem;
    z-index: 1;
    font-size: 0.14rem;
    color: var(--color-dark);
    user-select: none;
    align-items: center;
    display: flex;
    pointer-events: none;
    svg {
        width: 0.18rem;
        height: 0.18rem;
        margin-right: 0.06rem;
    }
`
