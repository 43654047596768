
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import Meta from 'components/Meta';
import Hero from 'components/home/Hero';
// import BenefitsCols from 'components/home/BenefitsCols'
// import Reviews from 'components/home/Reviews'
// import Curve from 'components/home/Curve'
// import Approach from 'components/home/Approach'
// import Expect from 'components/home/Expect'
// import Environment from 'components/home/Environment'
// import Start from 'components/Start'
import BlogFeed from 'components/BlogFeed';
import FixedHeader from 'components/FixedHeader';
import dynamic from 'next/dynamic';
const BenefitsCols = dynamic(() => import('components/home/BenefitsCols'));
const Approach = dynamic(() => import('components/home/Approach'));
const Expect = dynamic(() => import('components/home/Expect'));
const Environment = dynamic(() => import('components/home/Environment'));
const Curve = dynamic(() => import('components/home/Curve'));
const Start = dynamic(() => import('components/Start'));
// const BlogFeed = dynamic(() => import('components/BlogFeed'))
const Reviews = dynamic(() => import('components/home/Reviews'));
export default function Home() {
    const { t } = useTranslation('home');
    const data = {
        name: t('home_hero_name'),
        description: t('home_hero_description'),
        slogan: t('home_hero_slogan'),
        image: '/images/hero.jpg'
    };
    return (<>
            <Meta title={data.name} description={data.description}/>
            <FixedHeader />
            <Hero data={data}/>
            <BenefitsCols />
            <Approach />
            <Expect />
            <Environment />
            <Curve />
            <Reviews />
            <BlogFeed />
            <Start />
        </>);
}

    async function __Next_Translate__getStaticProps__18d044d0508__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18d044d0508__ as getStaticProps }
  