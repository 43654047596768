import { useState, useEffect } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import Reveal from 'components/Reveal'
import ArticleCard from 'components/blog/ArticleCard'
import Svg from 'components/Svg'
import { get } from 'utils/api'
import device from 'utils/device'

export default function BlogFeed() {
    const [data, setData] = useState([])

    useEffect(() => {
        const go = async () => {
            const response = await get('articles/landing')

            if (response?.data?.articles?.length) {
                setData(response.data.articles)
            }
        }
       
        go()
    },[])

    if (!data.length) {
        return null
    }

    return (
        <Container>
            <Texts>
                <Reveal>
                    <h2 className="h2">Longevity Knowledge Feed</h2>
                </Reveal>
            </Texts>
                <Content>
                    {data.map(item => (
                        <ArticleCard key={item.slug} data={item} as="h3" />
                    ))}
                </Content>
                <Cta>
                    <Link href="/articles">
                        <ViewAll href="/articles">View All<Svg name="arrow-right"/></ViewAll>
                    </Link>
                </Cta>
        </Container>
    )
}

const Container = styled.div`
    padding: 0 0 1.6rem 0;
    margin: 0 auto;
    width: var(--wrap);
    ${device.mobile} {
        padding: 0.8rem 0;
        margin: 0 auto 0.36rem auto;
        width: var(--wrap-mobile);
    }
`

const Texts = styled.div`
    width: 5.88rem;
    margin: 0 auto 0.36rem auto;
    text-align: center;
    h2 {
        margin-bottom: 0.16rem;
    }
    p {
        margin: 0;
    }
    ${device.mobile} {
        margin: 0 auto 0.36rem auto;
        width: var(--wrap-mobile);
        h2 {
            margin-bottom: 0.08rem;
        }
    }
`

const Content = styled.div`
    display: flex;
    margin: 0 -0.12rem;
    flex-wrap: wrap;
    ${device.mobile} {
        margin: 0;
    }
`

const Cta = styled.div`
    text-align: center;
    margin-top: 0.08rem;
`

const ViewAll = styled.a`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    svg {
        width: 0.24rem;
        height: 0.24rem;
        margin-lefT: 0.12rem;
    }
    ${device.mobile} {
        margin: 0;
    }
`