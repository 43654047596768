import styled from 'styled-components'

import device from 'utils/device'
import Image from 'components/Image'

export default function Mockup({ image }) {
    return (
        <ImageContainer>
            <Image src={image} alt="Vital Habits App" layout="fill" />
        </ImageContainer>
    )
}

const ImageContainer = styled.div`
    width: 2.1rem;
    height: 4.4rem;
    position: relative;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    > div {
        width: 100%;
        height: 100%;
        position: absolute;
    }
    ${device.mobile} {
        width: 1.3246rem;
        height: 2.7631rem;
    }
`



// import styled from 'styled-components'
// // import { Parallax } from 'react-scroll-parallax'

// import device from 'utils/device'
// import Image from 'components/Image'

// export default function Mockup({ image, y }) {
//     return (
//         <ImageContainer>
//             {/* <Parallax translateY={[y, 0]}> */}
//                 <Image src={image} alt="Vital Habits App" layout="fill" />
//             {/* </Parallax> */}
//         </ImageContainer>
//     )
// }

// const ImageContainer = styled.div`
//     width: 2.1rem;
//     height: 4.4rem;
//     position: relative;
//     left: 50%;
//     transform: translate3d(-50%, 0, 0);
//     > div {
//         width: 100%;
//         height: 100%;
//         position: absolute;
//     }
//     ${device.mobile} {
//         width: 1.3246rem;
//         height: 2.7631rem;
//     }
// `
