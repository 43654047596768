import { useEffect, useState } from 'react';

const useReveal = (elementRef, options) => {
  const [isVisible, setIsVisible] = useState(false);
  

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setIsVisible(state => state || entry.isIntersecting));
    }, options);

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [elementRef, options]);

  return isVisible;
};

export default useReveal
