import { useCallback, useRef } from 'react'
import styled from 'styled-components'

import device from 'utils/device'
import Logo from 'components/Logo'
import Mockup3 from 'components/Mockup3'
import Download from 'components/Download'
import useReveal from 'hooks/useReveal'


export default function Hero({ data, type, slogan, buttons, showArrow = true }) {
    const pathRef = useRef();

    const handleScroll = useCallback(() => {
        // const top = document.getElementById('intro').offsetTop
        const top = document.querySelector('#hero-block + div').offsetTop
        window.scrollTo({ top, behavior: 'smooth' })
    }, [])

    const isVisible = useReveal(pathRef, { threshold: 0.5 });

    return (
        <Full id="hero-block" type={type}>
            <Container>
                <Content>
                    <LogoWrap ref={pathRef} animate={isVisible}>
                        <Logo type={type} />
                    </LogoWrap>
                    <Illustration>
                        <Circle type={type} animate={isVisible} />
                        <SmallCircle type={type} animate={isVisible} />
                        <div className={`div-animation ${isVisible ? 'anim' : ''}`}>
                            <App1>
                                <Mockup3 y={20} image="/images/mockup-hero-2.png" />
                            </App1>
                            <App2>
                                <Mockup3 y={60} image="/images/mockup-hero-1.png" />
                            </App2>
                        </div>
                    </Illustration>
                    <h1 className={`h1 h1-animation ${isVisible ? 'anim' : ''}`}>{data.name}</h1>
                    <p className={`p1-animation ${isVisible ? 'anim' : ''}`}>{data.description}</p>
                    <p className={`p2-animation ${isVisible ? 'anim' : ''}`}>
                        <strong>
                            {slogan ? slogan : data.slogan}
                            {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 253.074 10.069">
                                <path className={`pathClass ${isVisible ? 'animate' : ''}`} d="M1.066 9.003s129.828-8.914 251-7.923" fill="none" stroke="#fb7e59" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                            </svg>*/}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187.599 10.091">
                                <path className={`pathClass ${isVisible ? 'animate' : ''}`} d="M3.112,10.835s95.948-8.914,185.5-7.923" transform="translate(-2.024 -1.832)" fill="none" stroke="#fb7e59" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                            </svg>
                        </strong>
                    </p>
                    <div className={`b-animation ${isVisible ? 'anim' : ''}`}>
                        {!buttons && <Download />}
                        {buttons && buttons()}
                    </div>
                    {showArrow && (
                        <ScrollDown animate={isVisible} onClick={handleScroll}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M18.3 14.29a1 1 0 0 0-1.41 0L13 18.17V3a1 1 0 0 0-1-1 1 1 0 0 0-1 1v15.18L7.12 14.3a1 1 0 0 0-1.41 0 1 1 0 0 0 0 1.41l5.59 5.59a1 1 0 0 0 1.41 0l5.59-5.59a1.027 1.027 0 0 0 0-1.42Z" />
                            </svg>
                        </ScrollDown>
                    )}
                </Content>
            </Container>
        </Full>
    )
}

const Full = styled.div`
    width: var(--full);
    margin: 0 auto;
    position: relative;
    color: ${props => props.type === 'light' ? '#fff' : 'var(--color-dark)'};
    h1 {
        color: ${props => props.type === 'light' ? '#fff' : 'var(--color-dark)'};
    }
    ${device.mobile} {
        width: 100%;
    }
    .h1-animation {
        opacity: 0;
        transform: translate3d(0, 0.3rem, 0);
        transition: opacity 600ms ease-in-out, transform 600ms ease-in-out;
        &.anim {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    .p1-animation {
        opacity: 0;
        transform: translate3d(0, 0.3rem, 0);
        transition: opacity 600ms 300ms ease-in-out, transform 600ms 300ms ease-in-out;
        &.anim {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    .p2-animation {
        opacity: 0;
        transform: translate3d(0, 0.3rem, 0);
        transition: opacity 600ms 600ms ease-in-out, transform 600ms 600ms ease-in-out;
        &.anim {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    .b-animation {
        opacity: 0;
        transform: translate3d(0, 0.3rem, 0);
        transition: opacity 600ms 900ms ease-in-out, transform 600ms 900ms ease-in-out;
        &.anim {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    .div-animation {
        opacity: 0;
        transform: translate3d(0, 0.3rem, 0);
        transition: opacity 600ms 900ms ease-in-out, transform 600ms 900ms ease-in-out;
        &.anim {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
`

const Container = styled.div`
    width: var(--wrap);
    margin: 0 auto;
    padding-top: 0.48rem;
    // height: 6.8rem;
    height: 6.4rem;
    @keyframes draw {
        0% {
          stroke-dasharray: 350, 350;
          stroke-dashoffset: 350;
        }
        100% {
          stroke-dasharray: 350, 350;
          stroke-dashoffset: 0;
        }
      }
    h1 {
        // margin-top: 1.6rem;
        margin-top: 1.2rem;
        margin-bottom: 0.08rem;
    }
    p {
        margin: 0 0 0.24rem;
        + p {
            margin: 0 0 0.48rem;
        }
    }
    strong {
        position: relative;
        svg {
            // width: 2.53074rem;
            // height: 0.10069rem;
            // margin-left: -1.26537rem;
            width: 1.87599rem;
            height: 0.10091rem;
            margin-left: -0.937995rem;
            position: absolute;
            top: 100%;
            left: 50%;
            margin-top: 0.04rem;
            

        }
        path {
            stroke-dasharray: 350, 350;
            stroke-dashoffset: 350;
        }
        .animate {
            animation: draw 800ms 1200ms ease-in-out forwards;
        }
    }
    ${device.mobile} {
        width: var(--wrap-mobile);
        padding-top: 0.48rem;
        height: auto;
        margin: 0 auto;
        h1 {
            margin-top: 0.24rem;
        }
    }
`

const Content = styled.div`
    width: 4.86rem;
    ${device.mobile} {
        width: auto;
    }
`

const Illustration = styled.div`
    position: absolute;
    width: 5.1rem;
    height: 5.1rem;
    // top: 1.7rem;
    top: 1.3rem;
    right: 1.61rem;
    ${device.mobile} {
        width: 3.44rem;
        height: 3.44rem;
        margin: 0.38rem 0 0 auto;
        position: relative;
        top: auto;
        right: auto;
    }
`

const App1 = styled.div`
    position: absolute;
    top: -0.9rem;
    right: 0.32rem;
    ${device.mobile} {
        /*top: -0.138rem;*/
        top: -0.338rem;
        right: 0.32rem;
    }
`

const App2 = styled.div`
    position: absolute;
    top: -0.1rem;
    right: 2.67rem;
    ${device.mobile} {
        /*top: 0.365rem;*/
        top: 0;
        right: 1.795rem;
    }
`

const Circle = styled.div`
    width: 5.1rem;
    height: 5.1rem;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    position: absolute;
    background: var(${props => props.type === 'light' ? '--color-accent' : '--color-sand'});
    ${device.mobile} {
        width: 3.44rem;
        height: 3.44rem;
    }
    opacity: 0;
    transform: scale(0);
    transition: opacity 1000ms 600ms ease-in-out, transform 1000ms 600ms ease-in-out;
    ${props=>props.animate ? 'opacity: 1; transform: scale(1);' : ''}
`

const SmallCircle = styled.div`
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    bottom: 0;
    right: 0.13rem;
    position: absolute;
    background: var(${props => props.type === 'light' ? '--color-purple' : '--color-green'});
    ${device.mobile} {
        width: 0.5396rem;
        height: 0.5396rem;
        right: 0.09rem;
    }
    opacity: 0;
    transform: scale(0);
    transition: opacity 1000ms 600ms ease-in-out, transform 1000ms 600ms ease-in-out;
    ${props=>props.animate ? 'opacity: 1; transform: scale(1);' : ''}
`

const ScrollDown = styled.div`
    width: 0.24rem;
    height: 0.24rem;
    display: block;
    cursor: pointer;
    margin-top: 1rem;
    svg {
        width: 0.24rem;
        height: 0.24rem;
    }
    ${device.mobile} {
        display: none;
    }
    opacity: 0;
    transform: translate3d(0, 0.3rem, 0);
    transition: opacity 600ms 1600ms ease-in-out, transform 600ms 1600ms ease-in-out;
    ${props=>props.animate ? 'opacity: 1; transform: translate3d(0, 0, 0);' : ''}
`

const LogoWrap = styled.div`
    opacity: 0;
    transform: translate3d(0, 0.3rem, 0);
    transition: opacity 600ms 1600ms ease-in-out, transform 600ms 1600ms ease-in-out;
    ${props=>props.animate ? 'opacity: 1; transform: translate3d(0, 0, 0);' : ''}
`