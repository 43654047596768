import { useRef } from 'react'

import useReveal from 'hooks/useReveal'

export default function Reveal({children, delay = '1'}) {
    const ref = useRef()
    const isVisible = useReveal(ref, { threshold: 0.5 })

    return (
        <div ref={ref} className={`reveal-${delay} ${isVisible ? 'show' : ''}`}>{children}</div>
    )
}