import { useState } from 'react'
import Image from 'next/image'

import { cn } from 'utils/helpers'

export default function ImageCom(props) {
    const [ready, setReady] = useState(!!props.priority)

    if (!props.src || typeof props.src !== 'string') {
        return ''
    }

    const data = props.src.startsWith('data:')
    const loading = !ready && !data

    const handleLoad = event => {
        event.persist()
        if (event.target.srcset) {
            setReady(true)
        }
    }

    // const handleSource = ({ src }) => {
    //     return src
    // }
    // loader={handleSource}

    return (
        <Image
            {...props}
            width={props.width}
            height={props.height}
            alt={props.alt || ''}
            title={props.alt}
            onLoad={handleLoad}
            className={cn('image', loading && 'imageLoading')}
        />
    )
}
