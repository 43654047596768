import styled from 'styled-components'
import Link from 'next/link'

function slugify(title) {
    return title
        .toLowerCase() // convert to lower case
        .trim() // remove leading and trailing whitespace
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace with '-'
        .replace(/-+/g, '-'); // collapse dashes
}

export default function Tags({ data, small }) {
    return (
        <Container small={small}>
            <Tag>{data.date}</Tag>
            {data?.category_names?.map((categoryName, k) => (
                <Link href={`/article-topic/${slugify(categoryName)}`} key={k}>
                    <a>
                        <Tag last={k === data.category_names.length - 1}>
                            {categoryName}
                        </Tag>
                    </a>
                </Link>
            ))}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.24rem;
    a:hover {
        text-decoration: underline;
    }
`

const Tag = styled.span`
    margin: 0 0 0.08rem 0;
    &:after {
        content: '•';
        margin: 0 0.1rem;
        display: ${props => (props.last ? 'none' : 'inline-block')};
    }
`
