import { stringify } from 'utils/qs'
import { serialize } from 'object-to-formdata'
import axios from 'axios'

export function getUrl(endpoint, query = {}, api = false) {
    const separator = !query || Object.keys(query).length === 0 ? '' : '?'
    const base = api ? process.env.NEXT_PUBLIC_API_URL : process.env.NEXT_PUBLIC_CMS_URL
    return `${base}${endpoint}${separator}${stringify(query)}`
}

export async function post(endpoint, data, api = false) {
    const url = getUrl(endpoint, {}, api)
    const body = data instanceof HTMLElement ? new FormData(data) : serialize(data, { indices: true, booleansAsIntegers: true })
    try {
        return await axios.post(url, body)
    } catch (err) {
        return err.response
    }
}

export async function get(endpoint, query, api = false) {
    const url = getUrl(endpoint, query, api)

    try {
        return await axios.get(url)
    } catch (err) {
        return err.response
    }
}

export async function getList(endpoint) {
    try {
        const { data } = await get(endpoint)

        const paths = data.data.map(item => ({
            params: { slug: item.slug }
        }))

        return {
            paths,
            fallback: true
        }
    } catch (e) {
        return {
            paths: [],
            fallback: true
        }
    }
}

// Page data for Next.js
export async function getData(endpoint, serverSide = false, api = false) {
    try {
        const response = await get(endpoint, null, api)

        if (!response) {
            return { props: {} }
        }

        const { data, status } = response

        if (status === 404) {
            return { notFound: true }
        }

        const props = status !== 200 || !data ? {} : data

        if (serverSide) {
            return {
                props
            }
        }

        return {
            props,
            revalidate: 60 * 60
        }
    } catch (err) {
        console.log('err')
        return { notFound: true }
    }
}
